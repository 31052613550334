import * as Yup from "yup";

export default function validationSchema() {
  return Yup.object({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    // username: Yup.string().required("Username is required"),
    email: Yup.string()
      .email("Enter a valid email")
      .required("Email is required"),
    phone: Yup.string()
      .matches(/^\d+$/, "Phone number is not valid")
      .required("Phone number is required"),
    // password: Yup.string()
    //   .required("Password is required")
    //   .min(8, "Password must be at least 8 characters"),
    role: Yup.string().required("Role is required"),
    isActive: Yup.boolean().required("Status is required"),
    // profilePic: Yup.mixed().required("A profile picture is required"),
    accessWarehouses: Yup.array()
      .of(Yup.string())
      .min(1, "At least one warehouse must be selected")
      .required("Warehouse access is required"),
  });
}
