import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import {
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  Grid,
  IconButton,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCategory from "./AddCategory/AddCategory";
import DeleteCategory from "./DeleteCategory/DeleteCategory";
import UpdateCategory from "./UpdateCategory/UpdateCategory";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { baseUrl } from "utils/baseUrl";
import ButtonAdd from "Components/ButtonAdd/ButtonAdd";

interface Category {
  id: string;
  name: string;
  description: string;
  imgCover: string;
}

export default function Categories() {
  const [categories, setCategories] = useState<Category[]>([]);
  const [searchText, setSearchText] = useState("");
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(
    null
  );
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${baseUrl}/categories`);
        setCategories(response.data);
      } catch (error) {
        // console.error("Error fetching categories", error);
        toast.error("Error fetching categories");
      }
    };

    fetchCategories();
  }, []);

  const handleOpenAddDialog = () => setOpenAddDialog(true);
  const handleCloseAddDialog = () => setOpenAddDialog(false);

  const handleOpenEditDialog = (category: Category) => {
    setSelectedCategory(category);
    setOpenEditDialog(true);
    // setTimeout(() => setOpenEditDialog(true), 0);
  };

  const handleCloseEditDialog = () => setOpenEditDialog(false);

  const handleOpenDeleteDialog = (category: Category) => {
    setSelectedCategory(category);
    setOpenDeleteDialog(true);
  };
  const handleCloseDeleteDialog = () => setOpenDeleteDialog(false);

  const onDeleteSuccess = (deletedCategoryId: string) => {
    setCategories((currentCategories) =>
      currentCategories.filter((category) => category.id !== deletedCategoryId)
    );
    setOpenDeleteDialog(false);
  };

  // In your Categories component
  const onUpdateSuccess = (updatedCategory: any) => {
    // First, check if updatedCategory is not undefined
    if (!updatedCategory) {
      console.error("Updated category data is undefined");
      return;
    }

    // Proceed with your logic if updatedCategory is defined
    setCategories((currentCategories) =>
      currentCategories.map((category) =>
        category.id === updatedCategory.id ? updatedCategory : category
      )
    );
    setOpenEditDialog(false);
  };

  // const onUpdateSuccess = (updatedCategory: Category) => {
  //   setCategories((currentCategories) =>
  //     currentCategories.map((category) =>
  //       category.id === updatedCategory.id ? updatedCategory : category
  //     )
  //   );
  //   setOpenEditDialog(false);
  // };

  const handleCategoryClick = (id: any) => {
    navigate(`/category/${id}`);
  };

  const handleAddCategorySuccess = (newCategory: any) => {
    setCategories([...categories, newCategory]);
  };

  return (
    <>
      <Box sx={{ p: 2 }}>
        {/* Search and Add Button */}
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
          <TextField
            label="Search"
            variant="outlined"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />

          {/* <Button
            component="a"
            href="#/categories"
            variant="contained"
            startIcon={<AddCircleOutlineIcon />}
            onClick={handleOpenAddDialog}
            sx={{
              bgcolor: (theme) => theme.palette.custom?.buttonBgColor,
              color: (theme) => theme.palette.custom?.buttonColor,
            }}
          >
             New Category
          </Button> */}

          <ButtonAdd onClick={handleOpenAddDialog} name="New Category" />
        </Box>

        {/* Display Categories */}
        <Grid
          container
          spacing={2}
          sx={{
            minWidth: "63vw",
            "& > .MuiGrid-item": {
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              minWidth: "300px",
            },
          }}
        >
          {categories
            .filter((category) =>
              category.name.toLowerCase().includes(searchText.toLowerCase())
            )
            .map((category) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={category.id}>
                <Card
                  sx={{
                    bgcolor: (theme) => theme.palette.custom?.card,
                  }}
                >
                  {/* <CardMedia
                    onClick={() => handleCategoryClick(category.id)}
                    component="img"
                    height="140"
                    image={category.imgCover}
                    alt={category.name}
                    sx={{
                      cursor: "pointer",
                    }}
                  /> */}
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {category.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {category.description}
                    </Typography>
                  </CardContent>
                  <Grid container justifyContent="flex-end">
                    <IconButton
                      onClick={() => handleCategoryClick(category.id)}
                    >
                      <VisibilityIcon />
                    </IconButton>

                    <IconButton onClick={() => handleOpenEditDialog(category)}>
                      <EditIcon />
                    </IconButton>

                    <IconButton
                      onClick={() => handleOpenDeleteDialog(category)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Card>
              </Grid>
            ))}
        </Grid>
      </Box>

      <AddCategory
        open={openAddDialog}
        onClose={handleCloseAddDialog}
        onAddCategorySuccess={handleAddCategorySuccess}
        // onAdd={handleAddCategory}
      />

      <DeleteCategory
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        category={selectedCategory}
        onDeleteSuccess={onDeleteSuccess}
      />

      <UpdateCategory
        open={openEditDialog}
        onClose={handleCloseEditDialog}
        category={selectedCategory}
        onUpdateSuccess={onUpdateSuccess}
      />
    </>
  );
}
