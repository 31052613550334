import * as Yup from "yup";

export default function validationSchema() {
  return Yup.object({
    name: Yup.string().required("name is required"),
    description: Yup.string().max(300).optional(),
    // .required("description is required"),
    // quantity: Yup.number().min(1).required("Quantity is required"),
    price: Yup.number().min(1).required("Price is required"),
    minValue: Yup.number().min(1).required("minValue is required"),
    images: Yup.mixed().optional(),
    // .required("At least one image is required"),
    warehouse: Yup.mixed().required("warehouse is required"),

    // category: Yup.array().of(Yup.string()),
    // tag: Yup.array().of(Yup.string()),
  });
}
