import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import ReusableDialog from "Components/Dialog/Dialog";
import CustomPhoneInput from "Components/CustomPhoneInput/CustomPhoneInput";
import Dropdown from "Components/Dropdown/Dropdown";

export interface Types {
  formik: any;
  isSubmitting: boolean;
  handleClose: () => void;
  formTitle: string;
  saveButtonLabel: string;
  isEdit?: any;
  open: any;
  warehouses?: any;
  handleDropdownChangeWarehouse?: any;
  selectedWarehouses?: string[];
}

const FormFields: React.FC<Types> = ({
  formik,
  isSubmitting,
  handleClose,
  formTitle,
  saveButtonLabel,
  isEdit,
  open,
  selectedWarehouses,
  handleDropdownChangeWarehouse,
  warehouses,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const { sendData, error, isLoading: sendLoading } = useSendData();
  // const [warehouses, setWarehouses] = useState<any[]>([]);
  // const [loading, setLoading] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const [error, setError] = useState(null);
  // const [personName, setPersonName] = React.useState<string[]>([]);
  // const [warehouseNames, setWarehouseNames] = useState<string[]>([]);
  // const [selectAll, setSelectAll] = useState(false);
  // const [isImagesSelected, setIsImagesSelected] = useState(false);

  // const handleChange = (event: SelectChangeEvent<typeof personName>) => {
  //   const {
  //     target: { value },
  //   } = event;

  //   // Handle "Select All" option
  //   if (value[value.length - 1] === "all") {
  //     if (selectAll) {
  //       // If already selected, unselect all
  //       setSelectAll(false);
  //       setPersonName([]);
  //       formik.setFieldValue("accessWarehouses", []);
  //     } else {
  //       // If not selected, select all
  //       setSelectAll(true);
  //       setPersonName(warehouseNames);
  //       const allWarehouseIds = warehouses.map((wh: any) => wh.id);
  //       formik.setFieldValue("accessWarehouses", allWarehouseIds);
  //     }
  //   } else {
  //     // Handle individual checkbox selection
  //     setSelectAll(false);
  //     const nextSelectedWarehouses =
  //       typeof value === "string" ? value.split(",") : value;
  //     setPersonName(nextSelectedWarehouses);
  //     const selectedWarehouseIds = nextSelectedWarehouses
  //       .map((name) => {
  //         const warehouse = warehouses.find((wh: any) => wh.name === name);
  //         return warehouse ? warehouse.id : null;
  //       })
  //       .filter((v): v is string => v !== null);
  //     formik.setFieldValue("accessWarehouses", selectedWarehouseIds);
  //   }
  // };

  // useEffect(() => {
  //   const fetchUsers = async () => {
  //     const token = localStorage.getItem("userToken");
  //     try {
  //       const response = await axios.get(`${baseUrl}/warehouses`, {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       });
  //       setWarehouses(response.data);
  //       const warehouseNames = response.data.map(
  //         (warehouse: any) => warehouse.name
  //       );
  //       setWarehouseNames(warehouseNames);

  //       setLoading(false);
  //     } catch (error) {
  //       if (error instanceof Error) {
  //         setError(error.message);
  //       } else {
  //         // If it's not an Error instance or you don't know the type, you can set a default error message
  //         setError("An error occurred while fetching the users.");
  //         setLoading(false);
  //       }
  //     }
  //   };

  //   fetchUsers();
  // }, []);

  // if (loading) {
  //   return <div>Loading users...</div>;
  // }
  const roles = ["admin", "user"];

  return (
    <>
      <ReusableDialog
        formTitle={formTitle}
        formik={formik}
        isSubmitting={isSubmitting}
        saveButtonLabel={saveButtonLabel}
        isEdit={isEdit}
        onClose={handleClose}
        open={open}
      >
        <>
          <>
            <TextField
              margin="normal"
              fullWidth
              id="firstName"
              label="First Name"
              name="firstName"
              autoFocus
              value={formik.values.firstName}
              onChange={formik.handleChange}
              error={
                formik.touched.firstName && Boolean(formik.errors.firstName)
              }
              helperText={
                formik.touched.firstName ? formik.errors.firstName : ""
              }
              sx={{ flex: "1 0 45%" }}
            />
            <TextField
              margin="normal"
              fullWidth
              id="lastName"
              label="Last Name"
              name="lastName"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName ? formik.errors.lastName : ""}
              sx={{ flex: "1 0 45%" }}
            />

            <TextField
              margin="normal"
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email ? formik.errors.email : ""}
              sx={{ flex: "1 0 45%" }}
            />

            <CustomPhoneInput
              formik={formik}
              name="phone"
              value={formik.values.phone}
              label="Phone Number"
              sx={{ flex: "1 0 45%" }}
            />

            <>
              {/* <TextField
              margin="normal"
              fullWidth
              id="password"
              name="password"
              label="Password *"
              type={showPassword ? "text" : "password"} // تغيير نوع الإدخال بناءً على حالة إظهار/إخفاء كلمة المرور
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password ? formik.errors.password : ""}
              sx={{ flex: "1 0 45%" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            /> */}
            </>

            <FormControl fullWidth margin="normal" sx={{ flex: "1 0 45%" }}>
              <InputLabel id="role-label">Role *</InputLabel>
              <Select
                labelId="role-label"
                id="role"
                name="role"
                label="Role *"
                value={formik.values.role}
                onChange={formik.handleChange}
                error={formik.touched.role && Boolean(formik.errors.role)}
              >
                <MenuItem value={roles[0]}>Admin</MenuItem>
                <MenuItem value={roles[1]}>User</MenuItem>
                {/* Add other roles as necessary */}
              </Select>
            </FormControl>

            <FormControlLabel
              control={
                <Switch
                  checked={formik.values.isActive}
                  onChange={formik.handleChange}
                  name="isActive"
                />
              }
              label="Active User"
            />

            {/* <FormControl fullWidth>
              <InputLabel id="demo-multiple-checkbox-label">
                Access Warehouses
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                label="Access Warehouses"
                multiple
                // value={formik.values.accessWarehouses}
                value={selectAll ? warehouseNames : personName}
                onChange={handleChange}
                // input={<OutlinedInput label="Access Warehouses" />}
                // renderValue={(selected) => selected.join(", ")}
                renderValue={(selected) => {
                  if (selected.length === warehouseNames.length) {
                    return "All Warehouses";
                  }
                  return `${selected.length} selected`;
                }}
                MenuProps={MenuProps}
              >
                <MenuItem value="all">
                  <Checkbox checked={selectAll} />
                  <ListItemText primary="Select All" />
                </MenuItem>
                {warehouseNames.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox
                      checked={personName.includes(name) || selectAll}
                    />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}

            <Dropdown
              label="Select Warehouse"
              id="warehouses"
              multiple
              value={selectedWarehouses}
              onChange={handleDropdownChangeWarehouse}
              options={warehouses}
            />
          </>

          {/* <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              bgcolor: (theme) => theme.palette.custom?.buttonBgColor,
              color: (theme) => theme.palette.custom?.buttonColor,
              mt: 3,
              mb: 2,
            }}
            disabled={formik.isSubmitting}
          >
            Add User
          </Button> */}
        </>
      </ReusableDialog>
    </>
  );
};

export default FormFields;
