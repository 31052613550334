// import React, { useEffect } from "react";
// import { RouterProvider } from "react-router-dom";
// import AppRouter from "./routes";
// import { Toaster } from "react-hot-toast";
import { ToastContainer } from "react-toastify";

import "./App.scss";
import MyRoutes from "./routes";

function App() {

  return (
    <div className="App">
      {/* <Toaster /> */}
      <ToastContainer
        theme="colored"
        // position="top-center"
      />
      {/* <RouterProvider router={router} /> */}
      <MyRoutes />
    </div>
  );
}

export default App;
