import React from "react";
import { useFormik } from "formik";
import { WarehouseTypes } from "../types/types";
import FormFields from "../FormFields";
import validationSchema from "../validationSchema";
import useSendData from "Components/Axios/SendData";
import ErrorDisplay from "Components/ErrorAndLoading/ErrorDisplay";
import Loading from "Components/ErrorAndLoading/Loading";

type AddWarehouseProps = {
  warehouse?: WarehouseTypes;
  handleClose: () => void;
  setWarehouses: React.Dispatch<React.SetStateAction<WarehouseTypes[]>>;
  open: any;
};

// 86277724860123
// 20173320144459
export default function AddWarehouse({
  handleClose,
  setWarehouses,
  open,
}: AddWarehouseProps) {
  const { sendData, error: sendError, isLoading: sendLoading } = useSendData();

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      city: "",
      zipCode: "",
      phone: "",
      street: "",
      state: "",
    },

    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const submittedData = await sendData({
        endpoint: "warehouses",
        data: values,
        isJson: true,
        method: "post",
      });
      if (submittedData) {
        setWarehouses((prevWarehouses) => [
          ...prevWarehouses,
          submittedData.warehouse,
        ]);
        resetForm();
        handleClose();
      }
    },
  });

  return (
    <>
      <>
        <ErrorDisplay error={sendError} />
        <Loading isLoading={sendLoading} />
      </>
      <FormFields
        formik={formik}
        isSubmitting={formik.isSubmitting}
        handleClose={handleClose}
        open={open}
        formTitle={" New Warehouse"}
        saveButtonLabel={"Create Warehouse"}
      />
    </>
  );
}
