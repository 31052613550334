import FormFields from "../FormFields";
import ErrorDisplay from "Components/ErrorAndLoading/ErrorDisplay";
import useSendData from "Components/Axios/SendData";
import { useFormik } from "formik";
import Loading from "Components/ErrorAndLoading/Loading";
import { toast } from "react-toastify";
import { useEffect } from "react";
import validationSchema from './../validationSchema';


type Types = {
  handleClose: () => void;
  onAddSupply: any;
  open: any;
  // id: any;
};

export default function AddPurchase({
  handleClose,
  onAddSupply,
  // id,
  open,
}: Types) {
  const { sendData, error: sendError, isLoading: sendLoading } = useSendData();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      date: "",
      vendorName: '',
      vendor: "",
      warehouse: "",
      product: [],
      notes: "",
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      // console.log("Form submission values:", values);
      const submittedData = await sendData({
        endpoint: "purchases",
        data: values,
        isJson: true,
        method: "post",
      });
      if (submittedData && submittedData.purchase) {
        onAddSupply((prevWarehouses: any) => [
          ...prevWarehouses,
          submittedData.purchase,
        ]);
        // onAddSupply(submittedData.purchase);
        resetForm();
        handleClose();
      }
    },
  });

  const errorToDisplay = sendError;
  useEffect(() => {
    if (sendError) {
      toast.error(sendError || "An error occurred. Please check your input.");
    }
  }, [sendError]);

  
  return (
    <>
      <>
        <ErrorDisplay error={errorToDisplay} />
        <Loading isLoading={sendLoading} />
      </>
      <FormFields
        formik={formik}
        isSubmitting={formik.isSubmitting}
        handleClose={handleClose}
        open={open}
        formTitle={" New Purchase"}
        saveButtonLabel={"Create Purchase"}
      />
    </>
  );
}
