import "./Login.scss";
import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Box, Button, TextField, CircularProgress } from "@mui/material";
import { baseUrl } from "utils/baseUrl";
import CryptoJS from "crypto-js";

interface LoginProps {
  saveUser: () => void;
}

export default function Login({ saveUser }: LoginProps) {
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState<boolean>(false);
  const [isOtpSent, setIsOtpSent] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [errMassage, setErrMassage] = useState<string | null>(null);

  const notify = (msg: string, type: "success" | "error") => {
    if (type === "success") {
      toast.success(msg);
    } else {
      toast.error(msg);
    }
  };

  // const handleLogout = useCallback(() => {
  //   localStorage.removeItem("userToken");
  //   localStorage.removeItem("user");
  //   navigate("/login");
  // }, [navigate]);

  async function sendOtp(email: string) {
    setisLoading(true);
    try {
      const response = await axios.post(`${baseUrl}/auth/signin`, { email });
      if (response.data.message === "OTP sent to your email") {
        setIsOtpSent(true);
        setErrMassage(null);
        notify("OTP sent. Please check your email.", "success");
      } else {
        notify(response.data.message || "Failed to send OTP", "error");
      }
    } catch (err: any) {
      const errorMessage =
        err.response?.data?.message || "An error occurred during OTP request";
      notify(errorMessage, "error");
      setErrMassage(errorMessage);
    } finally {
      setisLoading(false);
    }
  }

  async function verifyOtp(otp: string) {
    setisLoading(true);
    try {
      const response = await axios.post(`${baseUrl}/auth/verifyOtp`, {
        email,
        otp,
      });

      const { data } = response;

      if (data.message === "success") {
        const encryptedEmail = CryptoJS.AES.encrypt(
          data.data.user.email,
          "secret key 1oknvgudcxas23"
        ).toString();

        const encryptedId = CryptoJS.AES.encrypt(
          data.data.user.id.toString(),
          "secret key 1oknvgudcxas23"
        ).toString();

        localStorage.setItem("encryptedId", encryptedId);
        localStorage.setItem("encryptedEmail", encryptedEmail);

        localStorage.setItem("userToken", data.token);
        localStorage.setItem("user", JSON.stringify(data.data.user));
        notify("Logged in successfully", "success");
        saveUser();
        navigate("/dashboard");
      } else {
        notify(data.message || "Invalid OTP", "error");
      }
    } catch (err: any) {
      const errorMessage =
        err.response?.data?.message ||
        "An error occurred during OTP verification";
      notify(errorMessage, "error");
      setErrMassage(errorMessage);
    } finally {
      setisLoading(false);
    }
  }

  let emailSchema = Yup.object({
    email: Yup.string().email("Invalid email").required("Email is Required"),
  });

  let otpSchema = Yup.object({
    otp: Yup.string().required("OTP is Required"),
  });

  const emailFormik = useFormik({
    initialValues: { email: "" },
    validationSchema: emailSchema,
    onSubmit: (values) => {
      setEmail(values.email);
      sendOtp(values.email);
    },
  });

  const otpFormik = useFormik({
    initialValues: { otp: "" },
    validationSchema: otpSchema,
    onSubmit: (values) => verifyOtp(values.otp),
  });

  return (
    <Box className="login-page">
      <Box className="formParent">
        {!isOtpSent ? (
          <form onSubmit={emailFormik.handleSubmit}>
            <h3>Enter your email</h3>
            {errMassage && (
              <div className="alert alert-danger">{errMassage}</div>
            )}
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              margin="normal"
              id="email"
              name="email"
              value={emailFormik.values.email}
              // onChange={emailFormik.handleChange}
              onChange={(e) => {
                emailFormik.handleChange(e);
                setErrMassage(null); 
              }}
              onBlur={emailFormik.handleBlur}
              error={
                emailFormik.touched.email && Boolean(emailFormik.errors.email)
              }
              helperText={emailFormik.touched.email && emailFormik.errors.email}
            />
            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{ mt: 3, mb: 2 }}
            >
              {isLoading ? <CircularProgress size={24} /> : "Send OTP"}
            </Button>
          </form>
        ) : (
          <form onSubmit={otpFormik.handleSubmit}>
            <h3>Enter OTP</h3>
            {errMassage && (
              <div className="alert alert-danger">{errMassage}</div>
            )}
            <TextField
              label="OTP"
              variant="outlined"
              fullWidth
              margin="normal"
              id="otp"
              name="otp"
              value={otpFormik.values.otp}
              onChange={otpFormik.handleChange}
              onBlur={otpFormik.handleBlur}
              error={otpFormik.touched.otp && Boolean(otpFormik.errors.otp)}
              helperText={otpFormik.touched.otp && otpFormik.errors.otp}
            />
            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{ mt: 3, mb: 2 }}
            >
              {isLoading ? <CircularProgress size={24} /> : "Verify OTP"}
            </Button>
          </form>
        )}
      </Box>
    </Box>
  );
}
