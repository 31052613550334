import { useFormik } from "formik";
import { toast } from "react-toastify";
import { baseUrl } from "utils/baseUrl";
import axios from "axios";
import React, { useEffect, useState } from "react";
import validationSchema from "./validationSchema";
import FormFields from "./FormFields";

interface EditUserProps {
  user: UserTypes;
  handleClose: () => void;
  setUsers: React.Dispatch<React.SetStateAction<UserTypes[]>>;
  open: any;
}

export interface UserTypes {
  id: string;
  firstName: string;
  lastName: string;
  // username: string;
  email: string;
  phone: string;
  isActive: boolean;
  password: string;
  role: string;
  // profilePic: File | null;
  accessWarehouses: any;

  name?: string;
}

// The EditWarehouse component
export default function EditUser({
  user,
  handleClose,
  setUsers,
  open,
}: EditUserProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [warehouseNames, setWarehouseNames] = useState<string[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [personName, setPersonName] = React.useState<string[]>([]);

  const [selectedWarehouses, setSelectedWarehouses] = useState<string[]>([]);
  const [warehouses, setWarehouses] = useState<{ id: string; name: string }[]>(
    []
  );

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem("userToken");
        const response = await axios.get(`${baseUrl}/users/${user.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Set initial warehouse selection
        const selectedWarehouses = response.data.accessWarehouses.map(
          (wh: any) => wh.name
        );
        setPersonName(selectedWarehouses);
      } catch (error) {
        console.error("Error fetching user data:", error);
        toast.error("Failed to load user data.");
      }
    };

    const fetchWarehouses = async () => {
      try {
        const token = localStorage.getItem("userToken");
        const response = await axios.get(`${baseUrl}/warehouses`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setWarehouses(response.data);
        setWarehouseNames(
          response.data.map((warehouse: any) => warehouse.name)
        );
      } catch (error) {
        toast.error("Failed to load warehouses.");
      }
    };

    fetchUserData();
    fetchWarehouses();
  }, [user.id]);

  useEffect(() => {
    const fetchWarehouses = async () => {
      const token = localStorage.getItem("userToken");
      try {
        const response = await axios.get(`${baseUrl}/warehouses`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const formattedWarehouses = response.data.map((warehouse: any) => ({
          id: warehouse.id,
          name: warehouse.name,
        }));
        setWarehouses(formattedWarehouses);
      } catch (error) {
        console.error("Error fetching warehouses:", error);
        toast.error("Error fetching warehouses");
      }
    };

    fetchWarehouses();
  }, []);

  const formik = useFormik({
    initialValues: {
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phone: user.phone,
      // password: user.password || "",
      isActive: Boolean(user.isActive),
      role: user.role,
      // accessWarehouses: user.accessWarehouses,
      // accessWarehouses: user.accessWarehouses.map((wh: any) => wh.id),
      accessWarehouses: user.accessWarehouses
        ? user.accessWarehouses.map((wh: any) => wh.id)
        : [],
    },
    validationSchema,
    onSubmit: async (values, formikHelpers) => {
      // console.log("Form Values:", values);
      const { setSubmitting } = formikHelpers;
      setSubmitting(true);

      let updatedWarehouseIds = [];
      if (Array.isArray(values.accessWarehouses)) {
        updatedWarehouseIds = values.accessWarehouses;
      } else if (typeof values.accessWarehouses === "string") {
        updatedWarehouseIds = values.accessWarehouses
          .split(",")
          .map((name) => {
            const warehouse = warehouses.find((wh) => wh.name === name.trim());
            return warehouse ? warehouse.id : null;
          })
          .filter((id) => id !== null);
      }

      const updatedValues = {
        ...values,
        accessWarehouses: updatedWarehouseIds,
      };

      const token = localStorage.getItem("userToken");
      try {
        const response = await axios.patch(
          `${baseUrl}/users/${user.id}`,
          updatedValues,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // console.log("Server Response:", response.data);
        const updatedUser = response.data;
        setUsers((prevUsers) =>
          prevUsers.map((u) => (u.id === user.id ? updatedUser : u))
        );

        toast.success("User updated successfully!");
        handleClose();
      } catch (error) {
        console.error("PUT Request Error:", error);
        toast.error("An error occurred while updating the user.");
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleDropdownChangeWarehouse = (newValue: string[]) => {
    setSelectedWarehouses(newValue);
    formik.setFieldValue("accessWarehouses", newValue);
  };

  useEffect(() => {
    const selectedWarehouseIds = user.accessWarehouses.map((wh: any) => wh.id);
    setSelectedWarehouses(selectedWarehouseIds);
    formik.setFieldValue("accessWarehouses", selectedWarehouseIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <>
      <>
        {/* <ErrorDisplay error={sendError} />
      <Loading isLoading={sendLoading} /> */}
      </>
      <FormFields
        formik={formik}
        isSubmitting={formik.isSubmitting}
        handleClose={handleClose}
        open={open}
        selectedWarehouses={selectedWarehouses}
        handleDropdownChangeWarehouse={handleDropdownChangeWarehouse}
        warehouses={warehouses}
        formTitle={" Edit User"}
        saveButtonLabel={"Edit User"}
      />
    </>
  );
}
