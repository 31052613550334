import { useFormik } from "formik";
import axios from "axios";
import { toast } from "react-toastify";
import { baseUrl } from "utils/baseUrl";
import { UserTypes } from "./types";
import validationSchema from "./validationSchema";
import FormFields from "./FormFields";
import { useEffect, useState } from "react";

type AddUserProps = {
  handleClose: () => void;
  setUsers: (value: React.SetStateAction<UserTypes[]>) => void;
  open: any;
};

export default function AddUser({ handleClose, setUsers, open }: AddUserProps) {
  const [selectedWarehouses, setSelectedWarehouses] = useState<string[]>([]);
  const [warehouses, setWarehouses] = useState<{ id: string; name: string }[]>(
    []
  );

  useEffect(() => {
    const fetchWarehouses = async () => {
      const token = localStorage.getItem("userToken");
      try {
        const response = await axios.get(`${baseUrl}/warehouses`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const formattedWarehouses = response.data.map((warehouse: any) => ({
          id: warehouse.id,
          name: warehouse.name,
        }));
        setWarehouses(formattedWarehouses);
      } catch (error) {
        console.error("Error fetching warehouses:", error);
        toast.error("Error fetching warehouses");
      }
    };

    fetchWarehouses();
  }, []);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      // username: "",
      email: "",
      phone: "",
      isActive: true,
      // password: "",
      role: "",
      // profilePic: null,
      accessWarehouses: [],
    },

    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        const { ...userData } = values;

        const response = await axios.post(`${baseUrl}/users`, userData);
        const addedUser = response.data.user as UserTypes;

        setUsers((prevUsers) => [...prevUsers, addedUser]);
        toast.success("User added successfully!");
        handleClose();
        formik.resetForm();
      } catch (error) {
        // handleError(error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleDropdownChangeWarehouse = (newValue: string[]) => {
    setSelectedWarehouses(newValue);
    formik.setFieldValue("accessWarehouses", newValue);
  };

  // if (loading) {
  //   return <div>Loading users...</div>;
  // }
  return (
    <>
      <>
        {/* <ErrorDisplay error={sendError} />
        <Loading isLoading={sendLoading} /> */}
      </>
      <FormFields
        formik={formik}
        isSubmitting={formik.isSubmitting}
        handleClose={handleClose}
        open={open}
        selectedWarehouses={selectedWarehouses}
        handleDropdownChangeWarehouse={handleDropdownChangeWarehouse}
        warehouses={warehouses}
        formTitle={" New User"}
        saveButtonLabel={"Create User"}
      />
    </>
  );
}
