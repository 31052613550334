// SupplyFormFields.tsx
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Tooltip from "@mui/material/Tooltip";

import {
  Box,
  Button,
  Container,
  CssBaseline,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { AllowToNumberOnly } from "Components/AllowToNumberOnly/AllowToNumberOnly";
import ConfirmDelete from "Components/Axios/ConfirmDelete";
import useSendData from "Components/Axios/SendData";
import Dropdown from "Components/Dropdown/Dropdown";
import MultipleImageUploader from "Components/MultipleImageUploader/MultipleImageUploader";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import CustomTextField from "Components/CustomTextField/CustomTextField";
import { NumericFormat } from "react-number-format";
// import CustomTextField from "./CustomTextField";

// Define the props for the new component
export interface SupplyFormFieldsProps {
  formik: any;
  selectedCategories: string[];
  handleDropdownChangeCategory: (event: SelectChangeEvent<string[]>) => void;
  selectedTags: string[];
  selectedWarehouse?: any;
  setSelectedWarehouse?: any;
  handleDropdownChangeTag: (event: SelectChangeEvent<string[]>) => void;
  handleDropdownChangeWarehouse?: (event: SelectChangeEvent<string[]>) => void;
  categories: any;
  tags: any;
  warehouses?: any;
  images: File[];
  currentImages?: string[];
  handleImagesChange: (newImages: File[]) => void;
  isSubmitting: boolean;
  handleClose: () => void;
  setSelectedCategories: (value: string[]) => void;
  setSelectedTags: (value: string[]) => void;
  setImages: (files: File[]) => void;

  formTitle: string;
  saveButtonLabel: string;
  handleRemoveImage?: any;
  selectedSupply?: any;
  setCurrentImages?: any;
  currentImage?: any;
  isImagesSelected?: any;
  setIsImagesSelected?: any;
  isEdit?: any;
  setSupplies?: any;
  supplies?: any;
}

const FormFields: React.FC<SupplyFormFieldsProps> = ({
  formik,
  selectedCategories,
  handleDropdownChangeCategory,
  selectedTags,
  handleDropdownChangeTag,
  handleDropdownChangeWarehouse,
  categories,
  tags,
  warehouses,
  images,
  currentImages,
  handleImagesChange,
  isSubmitting,
  handleClose,
  setSelectedCategories,
  setSelectedTags,
  setImages,
  formTitle,
  saveButtonLabel,
  selectedSupply,
  setCurrentImages,
  currentImage,
  setIsImagesSelected,
  isImagesSelected,
  isEdit,
  setSupplies,
  supplies,
  selectedWarehouse,
  setSelectedWarehouse,
  // handleRemoveImage
}) => {
  // const [currentImages, setCurrentImages] = useState(initialImages); // 'initialImages' is the initial state.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { sendData, error, isLoading: sendLoading } = useSendData();
  // const [isImagesSelected, setIsImagesSelected] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isSaving, setIsSaving] = useState(false);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [imageToDelete, setImageToDelete] = useState(null);
  const [showNotes, setShowNotes] = useState(false);

  // const [selectedImages, setSelectedImages] = useState<string[]>([]);
  const createImagePreview = (imageFile: any) => {
    return URL.createObjectURL(imageFile);
  };

  useEffect(() => {
    return () => {
      images.forEach((image) => {
        if (image instanceof File) {
          URL.revokeObjectURL(createImagePreview(image));
        }
      });
    };
  }, [images]);

  useEffect(() => {}, [currentImages]);

  const handleOpenConfirmDelete = (image: any, index: number) => {
    setImageToDelete({ image, index });
    setOpenConfirmDelete(true);
  };

  const handleDeleteSuccess = (imageIndex: any) => {
    const newCurrentImages = currentImages.filter((_, i) => i !== imageIndex);
    setCurrentImages(newCurrentImages);
  };

  const handleRemoveImage = (image: any, index: any, isCurrent: any) => {
    if (isCurrent) {
      handleOpenConfirmDelete(image, index);
    } else {
      const updatedImages = images.filter((_, i) => i !== index);
      setImages(updatedImages);
      formik.setFieldValue("images", updatedImages);
    }
  };
  //-----------------------
  const handleUpdateSupplyDetails = async () => {
    const bodyData = {
      name: formik.values.name,
      description: formik.values.description,
      quantity: formik.values.quantity,
      price: formik.values.price,
      minValue: formik.values.minValue,
      category: selectedCategories,
      tag: selectedTags,
      warehouse: selectedWarehouse,
    };

    try {
      const response = await sendData({
        endpoint: `supplies/${selectedSupply.id}`,
        data: bodyData,
        isJson: true,
        method: "put",
      });

      // setSupplies(response.equipment)
      if (response) {
        // Update the specific supply in your state
        setSupplies(
          supplies.map((supply: any) => {
            if (supply.id === selectedSupply.id) {
              return { ...supply, ...response.supply };
            }
            return supply;
          })
        );
      }
      // setCurrentImages(newImages);
    } catch (error) {
      console.error("Error updating supply details:", error);
      toast.error("Error updating supply details");
    }
  };

  //------- imgs
  const handleUploadImages = async (images: any) => {
    const formData = new FormData();
    images.forEach((image: any) => formData.append("images", image));

    try {
      await sendData({
        endpoint: `supplies/${selectedSupply.id}`,
        data: formData,
        method: "put",
      });

      // toast.success("Images uploaded successfully");
      // return response.data;
    } catch (error) {
      console.error("Error uploading images:", error);
      toast.error("Error uploading images");
    }
  };
  //-------------------

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleSaveChanges = async () => {
    setIsSaving(true);

    try {
      if (formik.dirty) {
        await handleUpdateSupplyDetails();
        // Reset formik's dirty state after successful update
        formik.resetForm(formik.initialValues);
      }

      if (isImagesSelected && images.length > 0) {
        await handleUploadImages(images);
        setIsImagesSelected(false);
      }
      handleClose();
    } catch (error) {
      console.error("Error during saving changes:", error);
      toast.error("An error occurred during saving changes.");
    } finally {
      setIsSaving(false);
    }
  };

  const handleNumberChange = (fieldName: string, decimal: boolean = false) =>
    AllowToNumberOnly(
      (name: string, value: any) => {
        formik.setFieldValue(name, value);
      },
      fieldName,
      decimal
    );

  return (
    <Container
      component="main"
      maxWidth="md"
      sx={{
        bgcolor: (theme) => theme.palette.custom?.dialogBgColor,
        color: (theme) => theme.palette.custom?.dialogColor,
      }}
    >
      {openConfirmDelete && imageToDelete && (
        <ConfirmDelete
          itemName={`image ${imageToDelete.index + 1}`}
          endpoint={`supplies/${selectedSupply.id}/images/${imageToDelete.index}`}
          open={openConfirmDelete}
          onClose={() => setOpenConfirmDelete(false)}
          onDeleted={() => handleDeleteSuccess(imageToDelete.index)}
          // onDeleted={() => {
          //
          //   const updatedImages = currentImages.filter(
          //     (_, i) => i !== imageToDelete.index
          //   );
          //   formik.setFieldValue("images", updatedImages);
          //   setOpenConfirmDelete(false);
          // }}
        />
      )}
      <CssBaseline />
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">{formTitle}</Typography>
          <IconButton
            edge="end"
            color="inherit"
            // onClick={handleCloseAddDialog}
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <Divider
        sx={{
          borderBottomWidth: 2,
          marginBottom: 2,
          marginTop: 1,
          width: "100% !important",
        }}
      />

      <DialogContent>
        <Box
          sx={{
            // marginTop: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          component="form"
          onSubmit={formik.handleSubmit}
          noValidate
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: 2,
              flexWrap: "wrap",
            }}
          >
            <>
              {/* Name field */}
              <CustomTextField
                name="name"
                label="Name"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
              {isEdit ? (
                <CustomTextField
                  name="quantity"
                  label="Quantity"
                  // type="number"
                  value={formik.values.quantity}
                  onChange={handleNumberChange("quantity")}
                  error={
                    formik.touched.quantity && Boolean(formik.errors.quantity)
                  }
                  helperText={formik.touched.quantity && formik.errors.quantity}
                />
              ) : (
                ""
              )}
              <Box
                sx={{
                  display: "flex",
                  // alignItems: "center",
                  justifyContent: "space-between",
                  // gap:'0 40px 0 0'
                }}
              >
                <Box
                  sx={{
                    mr: "20px",
                    width: "100%",
                  }}
                >
                  <CustomTextField
                    name="price"
                    label="Value Per"
                    // type="number"
                    value={formik.values.price}
                    // onChange={formik.handleChange}
                    onChange={handleNumberChange("price", true)}
                    error={formik.touched.price && Boolean(formik.errors.price)}
                    helperText={formik.touched.price && formik.errors.price}
                  />
                </Box>

                <Box
                  sx={{ display: "flex", alignItems: "center", width: "100%" }}
                >
                  <CustomTextField
                    name="minValue"
                    label="Alert Value"
                    value={formik.values.minValue}
                    onChange={handleNumberChange("minValue")}
                    error={
                      formik.touched.minValue && Boolean(formik.errors.minValue)
                    }
                    helperText={
                      formik.touched.minValue && formik.errors.minValue
                    }
                  />
                  <Tooltip
                    title="Enter the minimum quantity required to trigger a notification."
                    placement="top"
                  >
                    <IconButton>
                      <HelpOutlineIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>

              {!showNotes && (
                <Button onClick={() => setShowNotes(true)}>Add Notes</Button>
              )}

              {/* Description field */}
              {showNotes && (
                <CustomTextField
                  name="description"
                  label="Notes"
                  multiline
                  rows={4}
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                  helperText={
                    formik.touched.description && formik.errors.description
                  }
                />
              )}
            </>

            <>
              <Dropdown
                label="Select Category"
                id="category"
                multiple
                value={selectedCategories}
                onChange={handleDropdownChangeCategory}
                options={categories}
                // renderValue={(selected) => {
                //   if (selected.length === categories.length) {
                //     return "All Categories";
                //   }
                //   return `${selected.length} selected`;
                // }}
              />

              <Dropdown
                label="Select Tag"
                id="tag"
                multiple
                value={selectedTags}
                onChange={handleDropdownChangeTag}
                options={tags}
                // renderValue={(selected) =>
                //   selected.length === tags.length
                //     ? "All Tags"
                //     : `${selected.length} selected`
                // }
              />

              {isEdit ? (
                <FormControl
                  fullWidth
                  margin="dense"
                  error={
                    formik.touched.warehouse && Boolean(formik.errors.warehouse)
                  }
                >
                  <InputLabel id="warehouse-select-label">
                    Select Warehouse+
                  </InputLabel>
                  <Select
                    labelId="warehouse-select-label"
                    id="warehouse"
                    label="Select Warehouse"
                    // value={formik.values.warehouse}
                    // onChange={(e) =>
                    //   formik.setFieldValue("warehouse", e.target.value)
                    // }
                    value={
                      formik.values.warehouse.length > 0
                        ? formik.values.warehouse[0]
                        : ""
                    }
                    onChange={(e) => {
                      const value = e.target.value;
                      formik.setFieldValue("warehouse", [value]);
                      setSelectedWarehouse([value]);
                      console.log("Selected Warehouse:", [value]);
                    }}
                  >
                    {warehouses.map((warehouse: any) => (
                      // <MenuItem key={warehouse.id} value={warehouse.id}>
                      <MenuItem
                        key={warehouse.id}
                        value={warehouse.id.toString()}
                      >
                        {warehouse.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {formik.touched.warehouse && formik.errors.warehouse && (
                    <FormHelperText>{formik.errors.warehouse}</FormHelperText>
                  )}
                </FormControl>
              ) : (
                ""
              )}

              {!isEdit ? (
                <>
                  <FormControl
                    fullWidth
                    margin="dense"
                    error={
                      formik.touched.warehouse &&
                      Boolean(formik.errors.warehouse)
                    }
                  >
                    <InputLabel id="warehouse-select-label">
                      Select Warehouses
                    </InputLabel>
                    <Select
                      labelId="warehouse-select-label"
                      id="warehouse"
                      label="Select Warehouses"
                      multiple
                      value={formik.values.warehouse}
                      onChange={(e) => {
                        const value = e.target.value;
                        const previousIds = formik.values.warehouse || [];
                        const addedIds = value.filter(
                          (id: any) => !previousIds.includes(id)
                        );
                        const removedIds = previousIds.filter(
                          (id: any) => !value.includes(id)
                        );
                        let newWarehouseQuantities = {
                          ...formik.values.warehouseQuantities,
                        };

                        // Add new warehouses with default quantity
                        for (const id of addedIds) {
                          newWarehouseQuantities[id] = "";
                        }

                        // Remove unselected warehouses
                        for (const id of removedIds) {
                          delete newWarehouseQuantities[id];
                        }

                        // Update Formik values
                        formik.setFieldValue("warehouse", value);
                        formik.setFieldValue(
                          "warehouseQuantities",
                          newWarehouseQuantities
                        );
                      }}
                      renderValue={(selected) =>
                        selected
                          .map(
                            (id: any) =>
                              warehouses.find((w: any) => w.id === id)?.name ||
                              `ID: ${id}`
                          )
                          .join(", ")
                      }
                    >
                      {warehouses.map((warehouse: any) => (
                        <MenuItem key={warehouse.id} value={warehouse.id}>
                          {warehouse.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {formik.touched.warehouse && formik.errors.warehouse && (
                      <FormHelperText>{formik.errors.warehouse}</FormHelperText>
                    )}
                  </FormControl>

                  {/* Table for Warehouses and Quantities */}
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Warehouse</TableCell>
                          <TableCell>Quantity</TableCell>
                          <TableCell>Delete</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {formik.values.warehouse?.map(
                          (warehouseId: any, index: any) => {
                            const warehouse = warehouses.find(
                              (w: any) => w.id === warehouseId
                            );
                            return (
                              <TableRow key={warehouseId}>
                                <TableCell>{warehouse.name}</TableCell>
                                <TableCell>
                                  {/* <TextField
                                    type="number"
                                    value={
                                      formik.values.warehouseQuantities[
                                        warehouseId
                                      ]
                                    }
                                    onChange={(e) => {
                                      const newQuantity = e.target.value;
                                      const newWarehouseQuantities = {
                                        ...formik.values.warehouseQuantities,
                                      };
                                      newWarehouseQuantities[warehouseId] =
                                        newQuantity;
                                      formik.setFieldValue(
                                        "warehouseQuantities",
                                        newWarehouseQuantities
                                      );
                                    }}
                                    error={
                                      formik.errors.warehouseQuantities &&
                                      formik.errors.warehouseQuantities[
                                        warehouseId
                                      ]
                                    }
                                    helperText={
                                      formik.errors.warehouseQuantities &&
                                      formik.errors.warehouseQuantities[
                                        warehouseId
                                      ]
                                    }
                                  /> */}

                                  <NumericFormat
                                    value={
                                      formik.values.warehouseQuantities[
                                        warehouseId
                                      ] || ""
                                    }
                                    onValueChange={(values) => {
                                      const { floatValue } = values;
                                      const newWarehouseQuantities = {
                                        ...formik.values.warehouseQuantities,
                                      };
                                      newWarehouseQuantities[warehouseId] =
                                        floatValue || 0;
                                      formik.setFieldValue(
                                        "warehouseQuantities",
                                        newWarehouseQuantities
                                      );
                                    }}
                                    thousandSeparator
                                    decimalScale={2} 
                                    customInput={TextField} 
                                    fullWidth
                                    error={
                                      formik.errors.warehouseQuantities &&
                                      formik.errors.warehouseQuantities[
                                        warehouseId
                                      ]
                                    }
                                    helperText={
                                      formik.errors.warehouseQuantities &&
                                      formik.errors.warehouseQuantities[
                                        warehouseId
                                      ]
                                    }
                                  />
                                </TableCell>
                                <TableCell>
                                  <IconButton
                                    onClick={() => {
                                      const newWarehouses =
                                        formik.values.warehouse.filter(
                                          (id: any) => id !== warehouseId
                                        );
                                      formik.setFieldValue(
                                        "warehouse",
                                        newWarehouses
                                      );

                                      const newWarehouseQuantities = {
                                        ...formik.values.warehouseQuantities,
                                      };
                                      delete newWarehouseQuantities[
                                        warehouseId
                                      ];
                                      formik.setFieldValue(
                                        "warehouseQuantities",
                                        newWarehouseQuantities
                                      );
                                    }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            );
                          }
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              ) : (
                ""
              )}

              {/* <Dropdown
                label="Select Warehouse"
                id="warehouse"
                multiple
                showSelectAll={false}
                value={selectedWarehouse}
                onChange={handleDropdownChangeWarehouse}
                options={warehouses}
              /> */}

              <>{/* <FormControl fullWidth margin="dense"> */}</>

              <MultipleImageUploader
                multiple
                onImagesChange={handleImagesChange}
                initialImages={images}
                formik={formik}
              />

              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                {/* Current images */}
                {currentImages?.map((image, index) => (
                  <Box key={index} sx={{ position: "relative" }}>
                    <img
                      src={image}
                      alt={`Preview ${index}`}
                      width="100"
                      height="100"
                    />
                    <IconButton
                      onClick={() => handleRemoveImage(image, index, true)}
                      sx={{ position: "absolute", top: 0, right: 0 }}
                    >
                      <DeleteIcon color="error" />
                    </IconButton>
                  </Box>
                ))}
                {/* New images */}
                {images.map((image, index) => (
                  <Box key={`new-image-${index}`} sx={{ position: "relative" }}>
                    <img
                      src={URL.createObjectURL(image)}
                      alt={`New Preview ${index}`}
                      width="100px"
                      height="100px"
                    />
                    <IconButton
                      onClick={() => handleRemoveImage(image, index, false)}
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                      }}
                    >
                      <DeleteIcon
                        sx={{
                          color: "red",
                        }}
                      />
                    </IconButton>
                  </Box>
                ))}
              </Box>
            </>

            {/* <Button
                variant="contained"

              onClick={async () => {
                await handleUpdateSupplyDetails();
                if (images.length > 0) {
                  await handleUploadImages(images);
                }
              }}
              disabled={formik.isSubmitting || sendLoading}
            >
              Update Supply
            </Button>

            <Button
              variant="contained"

              onClick={async () => {
                if (images.length > 0) {
                  await handleUploadImages(images);
                  setIsImagesSelected(false); // Disable the button again
                }
              }}
              // disabled={!isImagesSelected} // Button is disabled if no images are selected
            >
              Save Images
            </Button> */}
            {/* {isEdit === true ? (
              <Button
                fullWidth
                sx={{
                  mt: 3,
                  mb: 2,
                  bgcolor: (theme) => theme.palette.custom?.buttonBgColor,
                  color: (theme) => theme.palette.custom?.buttonColor,
                }}
                onClick={handleSaveChanges}
                variant="contained"
                disabled={formik.isSubmitting || sendLoading || isSaving}
              >
                {saveButtonLabel}
              </Button>
            ) : (
            )} */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                bgcolor: (theme) => theme.palette.custom?.buttonBgColor,
                color: (theme) => theme.palette.custom?.buttonColor,
              }}
              disabled={isSubmitting}
            >
              {saveButtonLabel}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Container>
  );
};

export default FormFields;
