import axios from "axios";
import { useEffect, useState } from "react";

import { Box, Dialog, IconButton, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
// import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
// import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { GridRenderCellParams } from "@mui/x-data-grid";
import ConfirmDelete from "Components/Axios/ConfirmDelete";
import FetchData from "Components/Axios/FetchData";
import ErrorDisplay from "Components/ErrorAndLoading/ErrorDisplay";
import ReusableTable from "Components/Table/Table";
import { toast } from "react-toastify";
import { baseUrl } from "utils/baseUrl";
import AddTransfer from "./AddTransfer/AddTransfer";
import EditTransfer from "./EditTransfer/EditTransfer";
import TransferDetails from "./TransferDetails/TransferDetails";

// import DeleteWarehouse from "./DeleteWarehouse/DeleteWarehouse";
// import EditWarehouse from "./EditWarehouse/EditWarehouse";

interface types {
  id: string;
  date: string;
  fromWarehouse: WarehouseDetails;
  toWarehouse: WarehouseDetails;
  supplies: string[];
  equipments: string[];
  quantity: number;
  status: string;
  note: string;

  name?: string;
}

interface WarehouseDetails {
  id?: string;
  name?: string;
  toLowerCase?: any;
  // include other properties if necessary
}
// eslint-disable-next-line @typescript-eslint/no-redeclare
export default function Transfers() {
  const [selectedSupply, setSelectedSupply] = useState<types | null>(null);
  const [viewDetailsOpen, setViewDetailsOpen] = useState(false);

  const [error, setError] = useState<string | null>(null);

  const [warehouses, setWarehouses] = useState<types[]>([]);
  const [searchText, setSearchText] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedWarehouse, setSelectedWarehouse] = useState<types | null>(
    null
  );
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const [editingWarehouse, setEditingWarehouse] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [sidebarIsOpen, setSidebarIsOpen] = useState(true);

  const { data, error: usersError, isLoading } = FetchData("transfers");

  useEffect(() => {
    if (data) {
      if (Array.isArray(data)) {
        const transfers = data.map((transfer: any) => {
          return {
            ...transfer,
            date: new Date(transfer.date).toLocaleString("en-US", {
              timeZone: "America/New_York",
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            }),
          };
        });

        setWarehouses(transfers);
      } else {
        setError("Data is not in expected format");
      }
    }
  }, [data]);

  const handleOpenDialog = () => {
    setSelectedWarehouse(null);
    setOpenDialog(true);
  };

  // Function to handle closing the Dialog
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedWarehouse(null);
  };

  const handleDeleteClick = (warehouseId: any) => {
    const supply = warehouses.find((wh) => wh.id === warehouseId);
    if (supply) {
      setSelectedSupply(supply);
      setDeleteDialogOpen(true);
    } else {
      toast.error("transfer not found.");
    }
  };

  const handleEdit = (id: any) => {
    const transferToEdit = warehouses.find((wh) => wh.id === id);
    if (transferToEdit) {
      setEditingWarehouse(transferToEdit);
      setSelectedSupply(transferToEdit);
      setIsEditModalOpen(true);
    } else {
      toast.error("Transfer not found.");
    }
  };

  const columns: GridColDef[] = [
    { field: "date", headerName: "Date", flex: 1 },
    { field: "fromWarehouse", headerName: "From Warehouse", flex: 1 },
    { field: "toWarehouse", headerName: "To Warehouse", flex: 1 },
    // { field: "quantity", headerName: "Quantity", flex: 1 },
    { field: "status", headerName: "Status", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <>
          <IconButton onClick={() => handleOpenViewDetails(params.id)}>
            <VisibilityIcon />
          </IconButton>

          <IconButton onClick={() => handleEdit(params.id)}>
            {/* <EditIcon onClick={() => handleEdit(params.id)} /> */}
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDeleteClick(params.id)}>
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  const handleSearchChange = (event: any) => {
    setSearchText(event.target.value);
  };

  const filteredRows = warehouses
    ? warehouses
        .filter((warehouse) => {
          const searchLower = searchText.toLowerCase();
          const fromWarehouseNameLower =
            warehouse.fromWarehouse?.name?.toLowerCase() || "";

          return (
            warehouse.date.toLowerCase().includes(searchLower) ||
            warehouse.toWarehouse.name.toLowerCase().includes(searchLower) ||
            fromWarehouseNameLower.includes(searchLower) ||
            warehouse.status.toLowerCase().includes(searchLower)
          );
        })
        .map((warehouse) => {
          const fromWarehouseName = warehouse.fromWarehouse?.name ?? "N/A";
          const toWarehouseName = warehouse.toWarehouse?.name ?? "N/A";
          return {
            id: warehouse.id,
            date: warehouse.date,
            fromWarehouse: fromWarehouseName,
            toWarehouse: toWarehouseName,
            status: warehouse.status,
          };
        })
    : [];

  const onTransferAdded = async () => {
    const fetchData = async () => {
      const token = localStorage.getItem("userToken");
      try {
        const response = await axios.get(`${baseUrl}/transfers`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        // console.log('===============response.data=====================');
        // console.log(response.data);
        // console.log('====================================');
        setWarehouses(response.data);
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message);
        } else {
          // If it's not an Error instance or you don't know the type, you can set a default error message
          setError("An error occurred while fetching the transfers.");
        }
      }
    };

    fetchData();
  };

  const handleOpenViewDetails = (supplyId: any) => {
    const supply = warehouses.find((s: any) => s.id === supplyId);
    if (supply) {
      setSelectedSupply(supply);
      setViewDetailsOpen(true);
    }
  };

  const handleCloseViewDetails = () => {
    setViewDetailsOpen(false);
  };

  if (error) {
    return (
      <Box>
        <Typography color="error">Error: {error}</Typography>
        {/* Add buttons or links to retry or go back to another page */}
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="h4" fontWeight="medium">
        Transfers
      </Typography>

      <>
        <ErrorDisplay error={usersError || error} />
        {/* <Loading isLoading={isLoading} /> */}
      </>

      <ReusableTable
        searchText={searchText}
        onSearchChange={handleSearchChange}
        onAddButtonClick={handleOpenDialog}
        rows={filteredRows}
        columns={columns}
        loading={isLoading}
        addButtonName=" New transfer"
      />

      <AddTransfer
        open={openDialog}
        handleClose={handleCloseDialog}
        setWarehouse={setWarehouses}
        onTransferAdded={onTransferAdded}
      />

      {editingWarehouse && (
        <EditTransfer
          open={isEditModalOpen}
          transferId={selectedSupply.id}
          transfer={warehouses}
          setWarehous={setWarehouses}
          onTransferAdded={onTransferAdded}
          handleClose={() => setIsEditModalOpen(false)}
        />
      )}

      {/* <Dialog
        open={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <Box
          sx={{
            bgcolor: (theme) => theme.palette.custom?.dialogBgColor,
            color: (theme) => theme.palette.custom?.dialogColor,
          }}
        >
          {editingWarehouse && (
            <TransferDetails
              transferId={selectedSupply.id}
              transfer={warehouses}
              setWarehous={setWarehouses}
              onTransferAdded={onTransferAdded}
              handleClose={() => setIsEditModalOpen(false)}
            />
          )}
        </Box>
      </Dialog> */}

      <Dialog open={viewDetailsOpen} onClose={handleCloseViewDetails}>
        {selectedSupply && (
          <TransferDetails
            transferId={selectedSupply.id}
            open={viewDetailsOpen}
            handleClose={handleCloseViewDetails}
          />
        )}
      </Dialog>

      {deleteDialogOpen && selectedSupply && (
        <ConfirmDelete
          itemName={selectedSupply.name}
          endpoint={`transfers/${selectedSupply.id}`}
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
          onDeleted={() => {
            setWarehouses(
              warehouses.filter(
                (supply: any) => supply.id !== selectedSupply.id
              )
            );
            setDeleteDialogOpen(false);
          }}
        />
      )}
    </Box>
  );
}
