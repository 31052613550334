// import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";

import { toast } from "react-toastify";
import { Types } from "../types/Supplies-Types";
// import { useParams } from "react-router-dom";
// import validationSchema from "../validationSchema";
// import { FormDataHelper } from "../FormDataHelper";
import FetchData from "Components/Axios/FetchData";
import FormFields from "../FormFields";
// import { Box, Button, Typography } from "@mui/material";
import useSendData from "Components/Axios/SendData";
import ErrorDisplay from "Components/ErrorAndLoading/ErrorDisplay";
import Loading from "Components/ErrorAndLoading/Loading";
import axios from "axios";
import { baseUrl } from "utils/baseUrl";

// interface Supply {
//   id: string;
//   description: string;
//   quantity: number;
//   price: number;
//   minValue: number;
//   images: string[];
//   categories: any;
//   tags: any;
//   warehouse?: any;
//   name: string;
//   updatedAt?: string;
// }

interface EditSupplyProps {
  handleClose: () => void;
  setSupplies?: React.Dispatch<React.SetStateAction<Types[]>>;
  supply?: any; // use the correct type for supply
  supplies?: any;
  // onUpdateSuccess?: (updatedSupply: any) => void;
}

const EditSupply = ({
  handleClose,
  supply,
  setSupplies,
  supplies,
}: // onUpdateSuccess,
// openEditDialog,
EditSupplyProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentImages, setCurrentImages] = useState(supply?.images || []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { sendData, error: sendError, isLoading: sendLoading } = useSendData();
  const {
    data: categoriesData,
    error: categoriesError,
    isLoading: categoriesLoading,
  } = FetchData("categories");
  const {
    data: tagsData,
    error: tagsError,
    isLoading: tagsLoading,
  } = FetchData("tags");
  const { data: warehouseData } = FetchData("warehouses");
  const [warehouses, setWarehouses] = useState([]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [file, setFile] = useState<File[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [preview, setPreview] = useState<string[]>([]);
  const [isImagesSelected, setIsImagesSelected] = useState(false);
  const [tags, setTags] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [images, setImages] = useState<File[]>([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState([]);
  const [supplyData, setSupplyData] = useState<any>(null);

  useEffect(() => {
    if (categoriesData) setCategories(categoriesData);
    if (tagsData) setTags(tagsData);
    if (warehouseData) setWarehouses(warehouseData);
  }, [categoriesData, tagsData, warehouseData]);

  useEffect(() => {
    if (supply.id) {
      axios
        .get(`${baseUrl}/supplies/${supply.id}`)
        .then((response) => {
          setSupplyData(response.data.supply);
        })
        .catch((error) => {
          toast.error("Error fetching supply data.");
          console.error(error);
        });
    }
  }, [supply.id]);

  useEffect(() => {
    if (supplyData) {
      const categoryIds = Array.isArray(supplyData.categories)
        ? supplyData.categories.map((cat: any) => cat.id)
        : [supplyData.categories.id];
      const tagIds = Array.isArray(supplyData.tags)
        ? supplyData.tags.map((tag: any) => tag.id)
        : [supplyData.tags.id];
      const warehouseIds = Array.isArray(supplyData.warehouse)
        ? supplyData.warehouse.map((warehouse: any) => warehouse.id)
        : [supplyData.warehouse.id];
      setSelectedCategories(categoryIds);
      setSelectedTags(tagIds);
      setSelectedWarehouse(warehouseIds);
      formik.setValues({
        ...supplyData,
        categories: categoryIds,
        tags: tagIds,
        warehouse: warehouseIds,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplyData]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: supply?.name || "",
      description: supply?.description || "",
      quantity: supply?.quantity || "",
      price: supply?.price || "",
      minValue: supply?.minValue || "",
      images: supply?.images || [],
      categories: Array.isArray(supply?.categories)
        ? supply.categories.map((cat: any) => cat.id)
        : [],
      tags: Array.isArray(supply?.tags)
        ? supply.tags.map((tag: any) => tag.id)
        : [],
      warehouse: Array.isArray(supply?.warehouse)
        ? supply.warehouse.map((warehouse: any) => warehouse.id)
        : [],
    },
    // validationSchema,
    onSubmit: async (values) => {
      const id = supply?.id;
      if (!id) {
        toast.error("Invalid supply ID.");
        return;
      }

      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("description", values.description);
      formData.append("quantity", values.quantity.toString());
      formData.append("price", values.price.toString());
      formData.append("minValue", values.minValue.toString());

      const filteredCategories = selectedCategories.filter(
        (category) => category !== undefined && category !== null
      );
      if (filteredCategories.length > 0) {
        formData.append("categories", JSON.stringify(filteredCategories));
      } 
      // formData.append(
      //   "categories",
      //   JSON.stringify(
      //     filteredCategories.length > 0
      //       ? filteredCategories
      //       : []
      //   )
      // );

      const filteredTags = selectedTags.filter(
        (tag) => tag !== undefined && tag !== null
      );
      formData.append(
        "tags",
        JSON.stringify(filteredTags.length > 0 ? filteredTags : [])
      );

      // const filteredWarehouses = selectedWarehouse.filter(
      //   (warehouse) => warehouse !== undefined && warehouse !== null
      // );
      // formData.append(
      //   "warehouse",
      //   JSON.stringify(
      //     filteredWarehouses.length > 0
      //       ? filteredWarehouses
      //       : ["defaultWarehouse"]
      //   )
      // );

      const warehouseValues = Array.isArray(formik.values.warehouse)
        ? formik.values.warehouse.map((warehouse) => parseInt(warehouse, 10))
        : [parseInt(formik.values.warehouse, 10)];

      if (warehouseValues.length > 0) {
        formData.append("warehouse", JSON.stringify(warehouseValues));
      } else {
        formData.append("warehouse", JSON.stringify([1]));
      }

      images
        .filter((image) => image !== undefined && image !== null)
        .forEach((image) => formData.append("images", image));

      try {
        const submittedData = await sendData({
          endpoint: `supplies/${supply.id}`,
          data: formData,
          method: "put",
        });

        if (submittedData) {
          setSupplies((prevSupplies: any) =>
            prevSupplies.map((item: any) =>
              item.id === supply.id ? submittedData.supply : item
            )
          );
          handleClose();
        }
      } catch (error) {
        console.error("Error sending data:", error);
      }
    },
  });

  useEffect(() => {
    if (file && file.length > 0) {
      const newPreviews = file.map((f) => URL.createObjectURL(f));
      setPreview(newPreviews);
      return () => newPreviews.forEach((url) => URL.revokeObjectURL(url));
    }
    setPreview(supply?.images || []);
  }, [file, supply]);

  //------------------------------------------
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { error, isLoading } = useSendData();

  //------------------------------------------

  // const handleImagesChange = useCallback(
  //   (newImages: File[]) => {
  //     setImages(newImages); // Update the images state
  //     formik.setFieldValue("images", newImages); // Update the formik field
  //     setPreview(newImages.map((file) => URL.createObjectURL(file))); // Update the preview with new images
  //   },
  //   [formik]
  // );

  const handleImagesChange = useCallback(
    (newImages: File[]) => {
      setImages(newImages); // Update the images state
      formik.setFieldValue("images", newImages); // Update the formik field
      setPreview(newImages.map((file) => URL.createObjectURL(file))); // Update the preview with new images
      setIsImagesSelected(newImages.length > 0); // Update the button state
    },
    [formik]
  );

  useEffect(() => {
    return () =>
      images.forEach((file) => URL.revokeObjectURL(URL.createObjectURL(file)));
  }, [images]);

  const handleDropdownChange = (setterFunction: any) => (newValue: any) => {
    setterFunction(newValue);
    formik.setFieldValue("category", selectedCategories);
    formik.setFieldValue("tag", selectedTags);
  };

  const handleDropdownChangeCategory = handleDropdownChange(
    setSelectedCategories
  );
  const handleDropdownChangeTag = handleDropdownChange(setSelectedTags);

  const handleDropdownChangeWarehouse =
    handleDropdownChange(setSelectedWarehouse);

  // const currentImageURLs =
  //   supply?.images?.map((imagePath) => {
  //     if (!imagePath.startsWith(baseUrl)) {
  //       return `${imagePath}`;
  //     }
  //     return imagePath;
  //   }) || [];

  const currentImageURLs =
    supply?.images?.map((image: any) => {
      // Now TypeScript knows the structure of image and that it can be null
      if (image?.secure_url) {
        return image.secure_url;
      }
      return "";
    }) || [];

  return (
    <>
      <>
        <ErrorDisplay error={categoriesError || tagsError || sendError} />
        <Loading isLoading={categoriesLoading || tagsLoading || sendLoading} />
      </>
      <FormFields
        formTitle={"Edit Supply"}
        saveButtonLabel={"Update Supply"}
        formik={formik}
        selectedCategories={selectedCategories}
        handleDropdownChangeCategory={handleDropdownChangeCategory}
        selectedTags={selectedTags}
        handleDropdownChangeTag={handleDropdownChangeTag}
        handleDropdownChangeWarehouse={handleDropdownChangeWarehouse}
        categories={categories}
        tags={tags}
        warehouses={warehouses}
        images={images}
        handleImagesChange={handleImagesChange}
        isSubmitting={formik.isSubmitting}
        setSelectedCategories={setSelectedCategories}
        setSelectedTags={setSelectedTags}
        selectedWarehouse={selectedWarehouse}
        setSelectedWarehouse={setSelectedWarehouse}
        setImages={setImages}
        handleClose={handleClose}
        supplies={supplies}
        setSupplies={setSupplies}
        isEdit={true}
        currentImages={currentImageURLs}
        // currentImage={currentImages}
        setCurrentImages={setCurrentImages}
        selectedSupply={supply}
        isImagesSelected={isImagesSelected}
        setIsImagesSelected={setIsImagesSelected}
        // handleRemoveImage={handleRemoveImage}
      />

      {/* <br />
      <br />
      <hr />
      <hr />
      <hr /> */}
    </>
  );
};

export default EditSupply;
