import * as Yup from "yup";

export default function validationSchema() {
  return Yup.object({
    date: Yup.date().required("Date is required").nullable(), 
    vendorName: Yup.string().required("Vendor Name is required"),
    warehouse: Yup.string().required("Warehouse is required"),
    // notes: Yup.string().max(300, "Notes must be less than 300 characters"), // النصوص المرفقة اختيارية ولكن محددة بطول
    product: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.string().required("Product ID is required"),
          name: Yup.string().required("Product name is required"),
          quantity: Yup.number()
            .min(1, "Quantity must be at least 1")
            .required("Quantity is required"),
          price: Yup.number()
            .min(0, "Price must be 0 or more")
            .required("Price is required"),
          total: Yup.number()
            .min(0, "Total must be 0 or more")
            .required("Total is required"),
        })
      )
      .min(1, "At least one product must be added"), 
  });
}
